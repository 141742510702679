@import '@/_styles/import';
.helpButtonEnabled {
  outline: revert !important; // TODO: issue inside chatbot styles
}

.chatbotIframe {
  will-change: opacity;

  position: fixed;
  z-index: $z-index-chatbot-iframe;
  right: 0;
  bottom: 0;

  width: 192px;

  visibility: hidden;
  opacity: 0;
  border: 0;
  outline: none;

  transition: opacity 0.4s;

  &.loaded {
    visibility: visible;
    opacity: 1;
  }
}

@include media-breakpoint-down(sm) {
  .chatbotIframe {
    max-width: 90%;
  }
}
